<template>
  <div>
    <app-header />

    <main>
      <!-- slideshow content begin -->
      <div
        class="uk-section section-home-banner"
        style="margin-bottom: 1rem"
        v-if="!loading && !loading_slider"
      >
        <div class="home-bg">
          <img
            :src="slider.background"
            alt="png"
            style="position: inherit; z-index: -1"
          />
        </div>
        <div class="home-caption">
          <div class="uk-container">
            <div class="uk-grid-large uk-flex-middle uk-grid uk-grid-stack">
              <div class="uk-width-1-2@m in-slide-text uk-first-column">
                <textarea
                  rows="2"
                  class="uk-textarea"
                  style="font-size: 30px"
                  placeholder="Title"
                  v-model="slider.title"
                ></textarea>
                <p class="uk-text-lead">
                  <textarea
                    placeholder="Tagline"
                    v-model="slider.tagline"
                    rows="2"
                    class="uk-textarea"
                  ></textarea>
                </p>
                <div>
                  <label>Background Image</label>
                  <input type="file" ref="slider_background" class="uk-input" />
                </div>
                <p>
                  <a
                    href="#"
                    @click.prevent="updateSlider"
                    class="uk-button uk-button-primary"
                    >Save
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-section section-home-banner"
        style="margin-bottom: 1rem"
        v-if="loading || loading_slider"
      >
        <loading />
      </div>
      <!-- slideshow content end -->

      <!-- section content begin -->
      <div class="uk-section uk-section-primary uk-preserve-color in-equity-1">
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <h4>Our Services</h4>
            </div>
          </div>
          <div
            v-if="!loading && !loading_services"
            class="
              uk-grid-match
              uk-grid-medium
              uk-child-width-1-4@m
              uk-child-width-1-2@s
              uk-margin-bottom
              uk-grid
            "
          >
            <div
              class="uk-first-column"
              v-for="(service, i) in services"
              :key="`service-${i}`"
            >
              <div
                class="uk-card uk-card-body uk-card-default uk-border-rounded"
              >
                <div class="uk-flex uk-flex-middle">
                  <span class="in-product-name red">{{ i + 1 }}</span>
                  <h5 class="uk-margin-remove">
                    <input
                      type="text"
                      placeholder="Title"
                      v-model="service.title"
                      class="uk-input"
                    />
                  </h5>
                </div>
                <p>
                  <textarea
                    placeholder="Content"
                    rows="7"
                    v-model="service.content"
                    class="uk-textarea"
                  ></textarea>
                </p>
              </div>
            </div>
          </div>
          <loading v-if="loading || loading_services" />
          <div class="uk-text-center" v-if="!loading && !loading_services">
            <a
              href="#"
              class="uk-button uk-button-secondary"
              @click.prevent="updateServices"
              >Save</a
            >
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div
        class="
          uk-section
          in-equity-2
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-equity-2-bg.png')"
      >
        <div
          class="uk-container uk-margin-top"
          v-if="!loading && !loading_signals"
        >
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-2xlarge@m uk-text-center">
              <span class="uk-label uk-label-warning">
                <input
                  type="text"
                  class="uk-input"
                  v-model="signals.badge"
                  placeholder="Badge"
                />
                <i class="fas fa-arrow-right fa-xs uk-margin-small-left"></i>
              </span>
              <h1 class="uk-margin-top">
                <textarea
                  placeholder="Title"
                  v-model="signals.title"
                  class="uk-textarea uk-text-center"
                  rows="3"
                  style="font-size: 40px"
                ></textarea>
              </h1>
              <p class="uk-text-lead uk-margin-medium-top">
                <textarea
                  placeholder="Title"
                  v-model="signals.tagline"
                  class="uk-textarea"
                  rows="3"
                ></textarea>
              </p>
            </div>
            <div class="uk-width-2xlarge@m uk-margin-medium-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-4@m
                  uk-child-width-1-4@s
                  uk-text-center
                  in-feature-box
                "
              >
                <div
                  class="uk-first-column"
                  v-for="(item, i) in signals.items"
                  :key="`signals-item-${i}`"
                >
                  <a
                    href="#"
                    class="uk-display-block"
                    @click.prevent="openImageSelector(i)"
                    ><i class="fa fa-pencil-square"></i
                  ></a>
                  <input
                    type="file"
                    :ref="`signals_icon`"
                    style="display: none"
                    :id="`signals_icon_${i}`"
                    @change="changeSignalsIcon(i)"
                  />
                  <a href="#" v-if="!changing_icon">
                    <span class="in-icon-wrap">
                      <img
                        :src="item.icon"
                        alt="icon-1"
                        width="35"
                        height="42"
                        v-if="changed_icons[i] == false"
                      />
                      <image-preview
                        v-if="changed_icons[i] != false"
                        :image="changed_icons[i]"
                      />
                    </span>
                  </a>

                  <p class="uk-margin-top">
                    <input
                      type="text"
                      placeholder="Content"
                      v-model="item.content"
                      class="uk-input"
                    />
                  </p>
                </div>
              </div>
              <div class="uk-text-center" style="margin-top: 2rem">
                <a
                  href="#"
                  class="uk-button uk-button-secondary"
                  @click.prevent="updateSignals"
                  >Save</a
                >
              </div>
            </div>
          </div>
        </div>
        <loading v-if="loading || loading_signals" />
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div class="uk-section in-equity-3 in-offset-top-20">
        <community-plans :community="community" :loading="loading" />
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-decor-2.svg')"
      >
        <getting-started :getting_started="getting_started" :loading="loading" />
      </div>
      <!-- section content end -->
    </main>

    <app-footer :disclaimer="disclaimer" :loading="loading" />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import CommunityPlans from '../shared/CommunityPlans'
import GettingStarted from '../shared/GettingStarted'

export default {
  components: {
    AppHeader, AppFooter, CommunityPlans, GettingStarted
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      membership_plans: { 1: {}, 3: {}, 6: {}, 12: {} },
      slider: {},
      services: [],
      signals: {},
      community: {},
      getting_started: {},
      loading: true,
      loading_slider: false,
      loading_services: false,
      loading_signals: false,
      loading_getting_started: false,
      changed_icons: [false, false, false, false],
      changing_icon: false,
      disclaimer: "",
    };
  },

  methods: {
    changeSignalsIcon(i) {
      this.changed_icons[i] = this.$refs.signals_icon[i].files[0];
      this.changing_icon = true;

      setTimeout(() => {
        this.changing_icon = false;
      }, 10);
    },

    openImageSelector(i) {
      document.getElementById(`signals_icon_${i}`).click();
    },

    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/sitecontent/public-home").then((response) => {
        this.slider = response.data.slider;
        this.services = response.data.services;
        this.signals = response.data.signals;
        this.community = response.data.community;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    },

    updateSlider() {
      this.loading_slider = true;

      let data = new FormData();
      data.append("title", this.slider.title);
      data.append("tagline", this.slider.tagline);

      if (this.$refs.slider_background.files[0]) {
        data.append("background", this.$refs.slider_background.files[0]);
      }

      this.$axios
        .post("/api/v1/admin/sitecontent/public-home-slider", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.slider = response.data.slider;
          this.loading_slider = false;
        });
    },

    updateServices() {
      this.loading_services = true;

      this.$axios
        .post("/api/v1/admin/sitecontent/public-home-services", this.services)
        .then((response) => {
          this.$toasted.success("Updated");
          this.services = response.data.services;
          this.loading_services = false;
        });
    },

    updateSignals() {
      this.loading_signals = true;

      let data = new FormData();
      data.append("badge", this.signals.badge);
      data.append("title", this.signals.title);
      data.append("tagline", this.signals.tagline);

      this.signals.items.forEach((item, i) => {
        data.append(`items[${i}][content]`, item.content);

        if (this.$refs.signals_icon[i].files[0]) {
          data.append(`items[${i}][icon]`, this.$refs.signals_icon[i].files[0]);
        }
      });

      this.$axios
        .post("/api/v1/admin/sitecontent/public-home-signals", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.signals = response.data.signals;
          this.loading_signals = false;
        });
    }
  },
};
</script>
